import { useEffect } from "react";
import {
  FormControl,
  FormLabel,
  Input,
  Select,
  Checkbox,
  VStack,
  HStack,
  Button,
  Divider,
  Text,
  useToast,
  Flex,
  Box,
} from "@chakra-ui/react";
import { useForm, Controller } from "react-hook-form";
import { useStore } from "../../../store/store";
import useFetch from "../../../partials/utils/Interceptor/Interceptor";
import "../form.css";
import { flattenObject } from "../../../partials/utils/helper";
import { FaInfoCircle } from "react-icons/fa";
interface FormValues {
  policytype: string;
  insurer: string;
  comp_location: string;
  Location_Type: string;
  Description_of_Operation: string;
  Territory: string;
  prior_pending_litigation_date: string;
  retroactive_date: string;
  jurisdiction: string;
  company_turnover: string;
  employee_background_verification: string;
  security_system: string;
  business_continuity_plan: string;
  cyber_attack_history: string;
  confidential_information_storage: string;
  critical_data_recovery: string;
  outsourced_data_activity: string;
  personal_information_processing: string;
  potential_loss_of_profit: string;
  //base cover
  security_privacy_liability: string;
  corporate_security_liability: string;
  multimedia_liability: string;
  computer_system: string;
  unauthorized_disclosure: string;
  credit_monitoring_costs: string;
  crisis_management_costs: string;
  cyber_extortion_costs: string;
  data_restoration_costs: string;
  forensic_costs: string;
  legal_representation_costs: string;
  privacy_notification_costs: string;
  regulatory_fines_penalties: string;
  business_interruption_waiting_period: string;
  business_interruption_loss_limit: string;
  extended_reporting_period: number;
  //optional coverage
  court_attendance_costs: string;
  emergency_professional_fees: string;
  pci_dss_loss_coverage: string;
  estimated_daily_loss_of_profit: string;
  //premium
  start_date: string;
  end_date: string;
  policy_start_date: string;
  policy_end_date: string;
  Transit_from: string;
  Transit_to: string;
  expected_premium: number;
  sum_insured: number;
  previous_insurer: string;
  previous_policy_expiry: string;
}
const CyberInsurance = ({ product, editData, mode }: any) => {
  const toast = useToast();
  const { step, setStep } = useStore();
  const {
    handleSubmit,
    control,
    register,
    watch,
    setValue,
    formState: { errors },
  } = useForm<FormValues>({
    defaultValues: {
      prior_pending_litigation_date: "",
      retroactive_date: "",
      jurisdiction: "",
      company_turnover: "",
      employee_background_verification: "",
      security_system: "",
      business_continuity_plan: "",
      cyber_attack_history: "",
      confidential_information_storage: "",
      critical_data_recovery: "",
      outsourced_data_activity: "",
      personal_information_processing: "",
      potential_loss_of_profit: "",
      //base coverage
      security_privacy_liability: "",
      corporate_security_liability: "",
      multimedia_liability: "",
      computer_system: "",
      unauthorized_disclosure: "",
      credit_monitoring_costs: "100%",
      crisis_management_costs: "100%",
      cyber_extortion_costs: "100%",
      data_restoration_costs: "100%",
      forensic_costs: "100%",
      legal_representation_costs: "100%",
      privacy_notification_costs: "100%",
      regulatory_fines_penalties: "100%",
      business_interruption_waiting_period: "12 months",
      business_interruption_loss_limit: "",
      extended_reporting_period: 90,
      start_date: new Date().toISOString().split("T")[0],
      end_date: new Date(new Date().setDate(new Date().getDate() + 10))
        .toISOString()
        .split("T")[0],
      policy_start_date: "",
      policy_end_date: "",
    },
  });

  const onSubmit = async (data: any) => {
    const compId = editData?.id
      ? editData?.company_id
      : localStorage.getItem("comp_id");
    const formData = new FormData();

    Object.keys(data).forEach((key) => {
      const value = data[key];

      if (value instanceof FileList) {
        Array.from(value).forEach((file) => {
          formData.append(key, file as Blob);
        });
      } else {
        formData.append(key, value as string | Blob);
      }
    });

    formData.append("comp_id", compId.toString() || "");
    formData.append("product", product);

    try {
      const response = await useFetch.post("bid", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      toast({
        title: `Bid ${editData?.id ? "updated" : "created"} successfully`,
        description: "Bid will be in draft till confirmation",
        status: "success",
        duration: 3000,
        isClosable: true,
      });

      setStep(5);
    } catch (error) {
      console.error("Error:", error);

      toast({
        title: `Submission failed`,
        description: "There was an error creating the bid.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };
  useEffect(() => {
    if (editData) {
      const flattenedData = flattenObject(editData);
      for (let key in flattenedData) {
        if (flattenedData.hasOwnProperty(key)) {
          setValue(key as keyof FormValues, flattenedData[key]);
        }
      }
    }
  }, [editData]);
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <VStack spacing={4} align="stretch">
        <HStack gap={4}>
          <FormControl isInvalid={Boolean(errors?.policytype)}>
            <FormLabel>Policy Type*</FormLabel>
            <Select
              bg="white"
              borderRadius="21px"
              placeholder="Select policy type"
              {...register("policytype", { required: true })}
            >
              <option value="New">New</option>
              <option value="Renewal">Renewal</option>
            </Select>
            {errors.policytype && (
              <Text color="red.500" fontSize="sm">
                This field is required
              </Text>
            )}
          </FormControl>

          <FormControl isInvalid={Boolean(errors?.insurer)}>
            <FormLabel>Bidder Preference*</FormLabel>
            <Select
              bg="white"
              borderRadius="21px"
              placeholder="Preference"
              {...register("insurer", { required: true })}
            >
              <option value="Both">Both</option>
              <option value="Brokers">Brokers</option>
              <option value="INS_COMP">Insurance Company</option>
            </Select>
            <Text color="red.500" fontSize="sm">
              {errors.insurer && "This field is required"}
            </Text>
          </FormControl>
        </HStack>
        <HStack gap={4}>
          <FormControl isInvalid={Boolean(errors?.comp_location)}>
            <FormLabel>Where is your company located?*</FormLabel>
            <Input
              bg="white"
              borderRadius="21px"
              type="text"
              {...register("comp_location", { required: true })}
            />
            <Text color="red.500" fontSize="sm">
              {errors.comp_location && "This field is required"}
            </Text>
          </FormControl>

          <FormControl isInvalid={Boolean(errors?.Location_Type)}>
            <FormLabel>Location Type*</FormLabel>
            <Select
              bg="white"
              borderRadius="21px"
              placeholder="Location Type"
              {...register("Location_Type", { required: true })}
            >
              <option value="Single">Single</option>
              <option value="Multiple">Multiple</option>
            </Select>
            <Text color="red.500" fontSize="sm">
              {errors.Location_Type && "This field is required"}
            </Text>
          </FormControl>
        </HStack>
        <HStack gap={4}>
          <FormControl isInvalid={Boolean(errors?.Description_of_Operation)}>
            <FormLabel>Description of Operation*</FormLabel>
            <Input
              bg="white"
              borderRadius="21px"
              type="text"
              {...register("Description_of_Operation", { required: true })}
            />
            <Text color="red.500" fontSize="sm">
              {errors.Description_of_Operation && "This field is required"}
            </Text>
          </FormControl>

          <FormControl isInvalid={Boolean(errors?.Territory)}>
            <FormLabel>Territory*</FormLabel>
            <Select
              bg="white"
              borderRadius="21px"
              placeholder="Select Territory"
              {...register("Territory", { required: true })}
            >
              <option value="India Only">India Only</option>
              <option value="Worldwide excluding US Canada">
                Worldwide excluding US Canada
              </option>
              <option value="Worldwide">Worldwide</option>
            </Select>
            <Text color="red.500" fontSize="sm">
              {errors.Territory && "This field is required"}
            </Text>
          </FormControl>
        </HStack>
        <HStack gap={4}>
          <FormControl
            isInvalid={Boolean(errors?.prior_pending_litigation_date)}
          >
            <FormLabel>Prior and Pending Litigation Date*</FormLabel>
            <Input
              bg="white"
              borderRadius="21px"
              type="date"
              {...register("prior_pending_litigation_date", { required: true })}
            />
            {errors.prior_pending_litigation_date && (
              <Text color="red.500" fontSize="sm">
                This field is required
              </Text>
            )}
          </FormControl>

          <FormControl isInvalid={Boolean(errors?.retroactive_date)}>
            <Flex align="start">
            <FormLabel>Retroactive Date*</FormLabel>
              {mode === "Guided" && (
                <FaInfoCircle
                  fontSize="11px"
                  color="gray.500"
                  title="specific date set by the insurer that determines when coverage begins for incidents that occur"
                />
              )}
            </Flex>
            <Input
              bg="white"
              borderRadius="21px"
              type="date"
              {...register("retroactive_date", { required: true })}
            />
            {errors.retroactive_date && (
              <Text color="red.500" fontSize="sm">
                This field is required
              </Text>
            )}
          </FormControl>
        </HStack>

        <HStack gap={4}>
          <FormControl isInvalid={Boolean(errors?.jurisdiction)}>
            <FormLabel>Jurisdiction*</FormLabel>
            <Select
              bg="white"
              borderRadius="21px"
              placeholder="Select Jurisdiction"
              {...register("jurisdiction", { required: true })}
            >
              <option value="India Only">India Only</option>
              <option value="Worldwide excluding US Canada">
                Worldwide excluding US Canada
              </option>
              <option value="Worldwide">Worldwide</option>
            </Select>
            {errors.jurisdiction && (
              <Text color="red.500" fontSize="sm">
                This field is required
              </Text>
            )}
          </FormControl>

          <FormControl isInvalid={Boolean(errors?.company_turnover)}>
            <FormLabel>Company Turnover*</FormLabel>
            <Input
              bg="white"
              borderRadius="21px"
              type="text"
              {...register("company_turnover", { required: true })}
            />
            {errors.company_turnover && (
              <Text color="red.500" fontSize="sm">
                This field is required
              </Text>
            )}
          </FormControl>
        </HStack>

        <HStack gap={4}>
          <FormControl
            isInvalid={Boolean(errors?.employee_background_verification)}
          >
            <FormLabel>
              Do you perform employee background verification?*
            </FormLabel>
            <Select
              bg="white"
              borderRadius="21px"
              placeholder="Select"
              {...register("employee_background_verification", {
                required: true,
              })}
            >
              <option value="Yes">Yes</option>
              <option value="No">No</option>
            </Select>
            {errors.employee_background_verification && (
              <Text color="red.500" fontSize="sm">
                This field is required
              </Text>
            )}
          </FormControl>

          <FormControl isInvalid={Boolean(errors?.security_system)}>
            <FormLabel>
              Do you have a security system, firewall, and audit logs in place?*
            </FormLabel>
            <Select
              bg="white"
              borderRadius="21px"
              placeholder="Select"
              {...register("security_system", { required: true })}
            >
              <option value="Yes">Yes</option>
              <option value="No">No</option>
            </Select>
            {errors.security_system && (
              <Text color="red.500" fontSize="sm">
                This field is required
              </Text>
            )}
          </FormControl>
        </HStack>

        <HStack gap={4}>
          <FormControl isInvalid={Boolean(errors?.business_continuity_plan)}>
            <FormLabel>
              Do you have a business continuity plan (BCP) in place?*
            </FormLabel>
            <Select
              bg="white"
              borderRadius="21px"
              placeholder="Select"
              {...register("business_continuity_plan", { required: true })}
            >
              <option value="Yes">Yes</option>
              <option value="No">No</option>
            </Select>
            {errors.business_continuity_plan && (
              <Text color="red.500" fontSize="sm">
                This field is required
              </Text>
            )}
          </FormControl>

          <FormControl isInvalid={Boolean(errors?.cyber_attack_history)}>
            <FormLabel>
              Have you encountered any form of cyber attack in the past 5
              years?*
            </FormLabel>
            <Select
              bg="white"
              borderRadius="21px"
              placeholder="Select"
              {...register("cyber_attack_history", { required: true })}
            >
              <option value="Yes">Yes</option>
              <option value="No">No</option>
            </Select>
            {errors.cyber_attack_history && (
              <Text color="red.500" fontSize="sm">
                This field is required
              </Text>
            )}
          </FormControl>
        </HStack>

        <HStack gap={4}>
          <FormControl
            isInvalid={Boolean(errors?.confidential_information_storage)}
          >
            <FormLabel>
              Do you store any confidential information in your system?*
            </FormLabel>
            <Select
              bg="white"
              borderRadius="21px"
              placeholder="Select"
              {...register("confidential_information_storage", {
                required: true,
              })}
            >
              <option value="Yes">Yes</option>
              <option value="No">No</option>
            </Select>
            {errors.confidential_information_storage && (
              <Text color="red.500" fontSize="sm">
                This field is required
              </Text>
            )}
          </FormControl>

          <FormControl isInvalid={Boolean(errors?.critical_data_recovery)}>
            <FormLabel>
              Can you recover business critical data and systems?*
            </FormLabel>
            <Select
              bg="white"
              borderRadius="21px"
              placeholder="Select"
              {...register("critical_data_recovery", { required: true })}
            >
              <option value="Yes">Yes</option>
              <option value="No">No</option>
            </Select>
            {errors.critical_data_recovery && (
              <Text color="red.500" fontSize="sm">
                This field is required
              </Text>
            )}
          </FormControl>
        </HStack>

        <HStack gap={4}>
          <FormControl isInvalid={Boolean(errors?.outsourced_data_activity)}>
            <FormLabel>
              Have you outsourced data collection and data processing activity?*
            </FormLabel>
            <Select
              bg="white"
              borderRadius="21px"
              placeholder="Select"
              {...register("outsourced_data_activity", { required: true })}
            >
              <option value="Yes">Yes</option>
              <option value="No">No</option>
            </Select>
            {errors.outsourced_data_activity && (
              <Text color="red.500" fontSize="sm">
                This field is required
              </Text>
            )}
          </FormControl>

          <FormControl
            isInvalid={Boolean(errors?.personal_information_processing)}
          >
            <FormLabel>
              Does the company collect, maintain or process personal information
              of clients?*
            </FormLabel>
            <Select
              bg="white"
              borderRadius="21px"
              placeholder="Select"
              {...register("personal_information_processing", {
                required: true,
              })}
            >
              <option value="Yes">Yes</option>
              <option value="No">No</option>
            </Select>
            {errors.personal_information_processing && (
              <Text color="red.500" fontSize="sm">
                This field is required
              </Text>
            )}
          </FormControl>
        </HStack>

        <HStack gap={4}>
          <FormControl isInvalid={Boolean(errors?.potential_loss_of_profit)}>
            <FormLabel>
            Company likely to incur a loss of profit as a result of an IT network compromise and a total system downtime?*
            </FormLabel>
            <Select
              bg="white"
              borderRadius="21px"
              placeholder="Select"
              {...register("potential_loss_of_profit", { required: true })}
            >
              <option value="Immediately">Immediately</option>
              <option value="1 to 12 hours">1 to 12 hours</option>
              <option value="12 to 24 hrs">12 to 24 hrs</option>
              <option value="24 to 48 hrs">24 to 48 hrs</option>
              <option value=">48 hrs">{'>48 hrs'}</option>
            </Select>
            {errors.potential_loss_of_profit && (
              <Text color="red.500" fontSize="sm">
                This field is required
              </Text>
            )}
          </FormControl>
        </HStack>
        <Box borderTop="1px solid" mt={2} w="100%" gridColumn="span 2">
          <FormLabel color="darkorchid" fontWeight="600">
            Base Coverage
          </FormLabel>
        </Box>
        <HStack gap={4}>
          <FormControl isInvalid={Boolean(errors.security_privacy_liability)}>
           
            <Flex align="start">
            <FormLabel htmlFor="security_privacy_liability">
              Security & Privacy Liability*
            </FormLabel>
              {mode === "Guided" && (
                <FaInfoCircle
                  fontSize="11px"
                  color="gray.500"
                  title="Cover for Loss arising out of Security Breach and/or Privacy Breach"
                />
              )}
            </Flex>
            <Select
              background="white"
              borderRadius="21px"
              id="security_privacy_liability"
              placeholder="Select option"
              {...register("security_privacy_liability", {
                required: "Security & Privacy Liability is required",
              })}
            >
              <option value="yes">Yes</option>
              <option value="no">No</option>
            </Select>
            {errors.security_privacy_liability && (
              <Text color="red.500" fontSize="sm">
                {errors.security_privacy_liability.message}
              </Text>
            )}
          </FormControl>

          <FormControl isInvalid={Boolean(errors.corporate_security_liability)}>
          <Flex align="start">
          <FormLabel htmlFor="corporate_security_liability">
          Corporate Security Liability*

            </FormLabel>
              {mode === "Guided" && (
                <FaInfoCircle
                  fontSize="11px"
                  color="gray.500"
                  title=" Cover for Loss arising out of in Unauthorized Disclosure of Corporate Information"
                />
              )}
            </Flex>
            <Select
              background="white"
              borderRadius="21px"
              id="corporate_security_liability"
              placeholder="Select option"
              {...register("corporate_security_liability", {
                required: "Corporate Security Liability is required",
              })}
            >
              <option value="yes">Yes</option>
              <option value="no">No</option>
            </Select>
            {errors.corporate_security_liability && (
              <Text color="red.500" fontSize="sm">
                {errors.corporate_security_liability.message}
              </Text>
            )}
          </FormControl>
        </HStack>
        <HStack gap={4}>
          <FormControl isInvalid={Boolean(errors.multimedia_liability)}>
            
            <Flex align="start">
            <FormLabel htmlFor="multimedia_liability">
              Multimedia Liability*
            </FormLabel>
              {mode === "Guided" && (
                <FaInfoCircle
                  fontSize="11px"
                  color="gray.500"
                  title="Cover for Loss arising out of Multimedia Activities"
                />
              )}
            </Flex>
            <Select
              background="white"
              borderRadius="21px"
              id="multimedia_liability"
              placeholder="Select option"
              {...register("multimedia_liability", {
                required: "Multimedia Liability is required",
              })}
            >
              <option value="yes">Yes</option>
              <option value="no">No</option>
            </Select>
            {errors.multimedia_liability && (
              <Text color="red.500" fontSize="sm">
                {errors.multimedia_liability.message}
              </Text>
            )}
          </FormControl>

          <FormControl isInvalid={Boolean(errors.computer_system)}>
            
            <Flex align="start">
            <FormLabel htmlFor="computer_system">Computer System*</FormLabel>
              {mode === "Guided" && (
                <FaInfoCircle
                  fontSize="11px"
                  color="gray.500"
                  title="Includes computer hardware,software,firmware,it's related components operated by  Consultant in the provision of Professional Services."
                />
              )}
            </Flex>
            <Select
              background="white"
              borderRadius="21px"
              id="computer_system"
              placeholder="Select option"
              {...register("computer_system", {
                required: "Computer System is required",
              })}
            >
              <option value="yes">Yes</option>
              <option value="no">No</option>
            </Select>
            {errors.computer_system && (
              <Text color="red.500" fontSize="sm">
                {errors.computer_system.message}
              </Text>
            )}
          </FormControl>
        </HStack>
        <HStack gap={4}>
          <FormControl isInvalid={Boolean(errors.unauthorized_disclosure)}>
            
            <Flex align="start">
            <FormLabel htmlFor="unauthorized_disclosure">
              Unauthorized Disclosure*
            </FormLabel>
              {mode === "Guided" && (
                <FaInfoCircle
                  fontSize="11px"
                  color="gray.500"
                  title="Unauthorized Disclosure of data to include any accidental or negligent disclosure. "
                />
              )}
            </Flex>
            <Select
              background="white"
              borderRadius="21px"
              id="unauthorized_disclosure"
              placeholder="Select option"
              {...register("unauthorized_disclosure", {
                required: "Unauthorized Disclosure is required",
              })}
            >
              <option value="yes">Yes</option>
              <option value="no">No</option>
            </Select>
            {errors.unauthorized_disclosure && (
              <Text color="red.500" fontSize="sm">
                {errors.unauthorized_disclosure.message}
              </Text>
            )}
          </FormControl>

          {/* Text Input Fields */}
          <FormControl isInvalid={Boolean(errors.credit_monitoring_costs)}>
            <FormLabel htmlFor="credit_monitoring_costs">
              Credit Monitoring Costs*
            </FormLabel>
            
            <Input
              borderRadius="21px"
              background="white"
              id="credit_monitoring_costs"
              placeholder="Enter amount"
              {...register("credit_monitoring_costs", {
                required: "Credit Monitoring Costs is required",
              })}
            />
            {errors.credit_monitoring_costs && (
              <Text color="red.500" fontSize="sm">
                {errors.credit_monitoring_costs.message}
              </Text>
            )}
          </FormControl>
        </HStack>
        <HStack gap={4}>
          <FormControl isInvalid={Boolean(errors.crisis_management_costs)}>
            <FormLabel htmlFor="crisis_management_costs">
              Crisis Management Costs*
            </FormLabel>
            <Input
              borderRadius="21px"
              background="white"
              id="crisis_management_costs"
              placeholder="Enter amount"
              {...register("crisis_management_costs", {
                required: "Crisis Management Costs is required",
              })}
            />
            {errors.crisis_management_costs && (
              <Text color="red.500" fontSize="sm">
                {errors.crisis_management_costs.message}
              </Text>
            )}
          </FormControl>

          <FormControl isInvalid={Boolean(errors.cyber_extortion_costs)}>
            <FormLabel htmlFor="cyber_extortion_costs">
              Cyber Extortion Costs*
            </FormLabel>
            <Input
              borderRadius="21px"
              background="white"
              id="cyber_extortion_costs"
              placeholder="Enter amount"
              {...register("cyber_extortion_costs", {
                required: "Cyber Extortion Costs is required",
              })}
            />
            {errors.cyber_extortion_costs && (
              <Text color="red.500" fontSize="sm">
                {errors.cyber_extortion_costs.message}
              </Text>
            )}
          </FormControl>
        </HStack>
        <HStack gap={4}>
          <FormControl isInvalid={Boolean(errors.data_restoration_costs)}>
            <FormLabel htmlFor="data_restoration_costs">
              Data Restoration Costs*
            </FormLabel>
            <Input
              borderRadius="21px"
              background="white"
              id="data_restoration_costs"
              placeholder="Enter amount"
              {...register("data_restoration_costs", {
                required: "Data Restoration Costs is required",
              })}
            />
            {errors.data_restoration_costs && (
              <Text color="red.500" fontSize="sm">
                {errors.data_restoration_costs.message}
              </Text>
            )}
          </FormControl>

          <FormControl isInvalid={Boolean(errors.forensic_costs)}>
            <FormLabel htmlFor="forensic_costs">Forensic Costs*</FormLabel>
            <Input
              borderRadius="21px"
              background="white"
              id="forensic_costs"
              placeholder="Enter amount"
              {...register("forensic_costs", {
                required: "Forensic Costs is required",
              })}
            />
            {errors.forensic_costs && (
              <Text color="red.500" fontSize="sm">
                {errors.forensic_costs.message}
              </Text>
            )}
          </FormControl>
        </HStack>
        <HStack gap={4}>
          <FormControl isInvalid={Boolean(errors.legal_representation_costs)}>
            <FormLabel htmlFor="legal_representation_costs">
              Legal Representation Costs*
            </FormLabel>
            <Input
              borderRadius="21px"
              background="white"
              id="legal_representation_costs"
              placeholder="Enter amount"
              {...register("legal_representation_costs", {
                required: "Legal Representation Costs is required",
              })}
            />
            {errors.legal_representation_costs && (
              <Text color="red.500" fontSize="sm">
                {errors.legal_representation_costs.message}
              </Text>
            )}
          </FormControl>

          <FormControl isInvalid={Boolean(errors.privacy_notification_costs)}>
            
            <Flex align="start">
            <FormLabel htmlFor="privacy_notification_costs">
              Privacy Notification Costs*
            </FormLabel>
              {mode === "Guided" && (
                <FaInfoCircle
                  fontSize="11px"
                  color="gray.500"
                  title="It's a crucial document that informs individuals about how their personal data is collected, used, shared, and protected by an organization."
                />
              )}
            </Flex>
            <Input
              borderRadius="21px"
              background="white"
              id="privacy_notification_costs"
              placeholder="Enter amount"
              {...register("privacy_notification_costs", {
                required: "Privacy Notification Costs is required",
              })}
            />
            {errors.privacy_notification_costs && (
              <Text color="red.500" fontSize="sm">
                {errors.privacy_notification_costs.message}
              </Text>
            )}
          </FormControl>
        </HStack>
        <HStack gap={4}>
          <FormControl isInvalid={Boolean(errors.regulatory_fines_penalties)}>
            <FormLabel htmlFor="regulatory_fines_penalties">
              Regulatory Fines and Penalties*
            </FormLabel>
            <Input
              borderRadius="21px"
              background="white"
              id="regulatory_fines_penalties"
              placeholder="Enter amount"
              {...register("regulatory_fines_penalties", {
                required: "Regulatory Fines and Penalties is required",
              })}
            />
            {errors.regulatory_fines_penalties && (
              <Text color="red.500" fontSize="sm">
                {errors.regulatory_fines_penalties.message}
              </Text>
            )}
          </FormControl>

          <FormControl
            isInvalid={Boolean(errors.business_interruption_waiting_period)}
          >
            <FormLabel htmlFor="business_interruption_waiting_period">
              Business Interruption Waiting Period*
            </FormLabel>
            <Input
              borderRadius="21px"
              background="white"
              id="business_interruption_waiting_period"
              placeholder="Enter duration (days)"
              {...register("business_interruption_waiting_period", {
                required: "Business Interruption Waiting Period is required",
              })}
            />
            {errors.business_interruption_waiting_period && (
              <Text color="red.500" fontSize="sm">
                {errors.business_interruption_waiting_period.message}
              </Text>
            )}
          </FormControl>
        </HStack>
        <HStack gap={4}>
          <FormControl
            isInvalid={Boolean(errors.business_interruption_loss_limit)}
          >
            <FormLabel htmlFor="business_interruption_loss_limit">
              Business Interruption Loss Limit*
            </FormLabel>
            <Input
              borderRadius="21px"
              background="white"
              id="business_interruption_loss_limit"
              placeholder="Enter amount"
              {...register("business_interruption_loss_limit", {
                required: "Business Interruption Loss Limit is required",
              })}
            />
            {errors.business_interruption_loss_limit && (
              <Text color="red.500" fontSize="sm">
                {errors.business_interruption_loss_limit.message}
              </Text>
            )}
          </FormControl>

          <FormControl isInvalid={Boolean(errors.extended_reporting_period)}>
            
            <Flex align="start">
            <FormLabel htmlFor="extended_reporting_period">
              Extended Reporting Period (In days)*
            </FormLabel>
              {mode === "Guided" && (
                <FaInfoCircle
                  fontSize="11px"
                  color="gray.500"
                  title="Days to Report incidents or claims that occur after a policy has expired or been canceled."
                />
              )}
            </Flex>
            <Input
              borderRadius="21px"
              background="white"
              type="number"
              id="extended_reporting_period"
              placeholder="Enter duration (days)"
              {...register("extended_reporting_period", {
                required: "Extended Reporting Period is required",
              })}
            />
            {errors.extended_reporting_period && (
              <Text color="red.500" fontSize="sm">
                {errors.extended_reporting_period.message}
              </Text>
            )}
          </FormControl>
        </HStack>
        <Box borderTop="1px solid" mt={2} w="100%" gridColumn="span 2">
          <FormLabel color="darkorchid" fontWeight="600">
            Optional Coverage
          </FormLabel>
        </Box>
        <HStack gap={4}>
          <FormControl isInvalid={Boolean(errors.court_attendance_costs)}>
            <FormLabel htmlFor="court_attendance_costs">
              Court Attendance Costs (In $)*
            </FormLabel>
            <Input
              borderRadius="21px"
              background="white"
              id="court_attendance_costs"
              placeholder="Enter amount"
              {...register("court_attendance_costs", {
                required: "Court Attendance Costs is required",
              })}
            />
            {errors.court_attendance_costs && (
              <Text color="red.500" fontSize="sm">
                {errors.court_attendance_costs.message}
              </Text>
            )}
          </FormControl>

          <FormControl isInvalid={Boolean(errors.emergency_professional_fees)}>
            <FormLabel htmlFor="emergency_professional_fees">
              Emergency Professional Fees (in $)*
            </FormLabel>
            <Input
              borderRadius="21px"
              background="white"
              id="emergency_professional_fees"
              placeholder="Enter amount"
              {...register("emergency_professional_fees", {
                required: "Emergency Professional Fees is required",
              })}
            />
            {errors.emergency_professional_fees && (
              <Text color="red.500" fontSize="sm">
                {errors.emergency_professional_fees.message}
              </Text>
            )}
          </FormControl>
        </HStack>
        <HStack gap={4}>
          <FormControl isInvalid={Boolean(errors.pci_dss_loss_coverage)}>
            
            <Flex align="start">
            <FormLabel htmlFor="pci_dss_loss_coverage">
              PCI-DSS Loss Coverage (in $)*
            </FormLabel>
              {mode === "Guided" && (
                <FaInfoCircle
                  fontSize="11px"
                  color="gray.500"
                  title="Security standards designed to protect card information during and after a financial transaction."
                />
              )}
            </Flex>
            <Input
              borderRadius="21px"
              background="white"
              id="pci_dss_loss_coverage"
              placeholder="Enter amount"
              {...register("pci_dss_loss_coverage", {
                required: "PCI-DSS Loss Coverage is required",
              })}
            />
            {errors.pci_dss_loss_coverage && (
              <Text color="red.500" fontSize="sm">
                {errors.pci_dss_loss_coverage.message}
              </Text>
            )}
          </FormControl>

          <FormControl
            isInvalid={Boolean(errors.estimated_daily_loss_of_profit)}
          >
            <FormLabel htmlFor="estimated_daily_loss_of_profit">
              Estimated Daily Loss of Profit (in $)*
            </FormLabel>
            <Input
              borderRadius="21px"
              background="white"
              id="estimated_daily_loss_of_profit"
              placeholder="Enter amount"
              {...register("estimated_daily_loss_of_profit", {
                required: "Estimated Daily Loss of Profit is required",
              })}
            />
            {errors.estimated_daily_loss_of_profit && (
              <Text color="red.500" fontSize="sm">
                {errors.estimated_daily_loss_of_profit.message}
              </Text>
            )}
          </FormControl>
        </HStack>
        <Box borderTop="1px solid" mt={2} w="100%" gridColumn="span 2" />
        <HStack gap={4}>
          <FormControl isInvalid={Boolean(errors?.start_date)}>
            <FormLabel htmlFor="start_date">Bid's Start Date*</FormLabel>
            <Input
              borderRadius="21px"
              background="white"
              bg="white"
              id="start_date"
              type="date"
              {...register("start_date", {
                required: "Bid's start date is required",
              })}
            />
            {errors?.start_date && (
              <Text color="red.500" fontSize="sm">
                {errors.start_date.message}
              </Text>
            )}
          </FormControl>

          <FormControl isInvalid={Boolean(errors?.end_date)}>
            <FormLabel htmlFor="end_date">Bid's End Date*</FormLabel>
            <Input
              borderRadius="21px"
              background="white"
              bg="white"
              id="end_date"
              type="date"
              {...register("end_date", {
                required: "Bid's end date is required",
              })}
            />
            {errors?.end_date && (
              <Text color="red.500" fontSize="sm">
                {errors.end_date.message}
              </Text>
            )}
          </FormControl>
        </HStack>
        <HStack gap={4}>
          <FormControl id="policy_start_date">
            <FormLabel>Policy Start Date</FormLabel>
            <Input
              borderRadius="21px"
              background="white"
              bg="white"
              type="date"
              {...register("policy_start_date")}
            />
          </FormControl>

          <FormControl id="policy_end_date">
            <FormLabel>Policy End Date</FormLabel>
            <Input
              borderRadius="21px"
              background="white"
              bg="white"
              type="date"
              {...register("policy_end_date")}
            />
          </FormControl>
        </HStack>
        <HStack gap={4}>
          <FormControl isInvalid={Boolean(errors?.expected_premium)}>
            <Flex align="start">
              <FormLabel> Anticipated premium*</FormLabel>
              {mode === "Guided" && (
                <FaInfoCircle
                  fontSize="11px"
                  color="gray.500"
                  title="Anticipated premium for acquiring this policy."
                />
              )}
            </Flex>
            <Input
              borderRadius="21px"
              background="white"
              bg="white"
              id="expected_premium"
              type="number"
              {...register("expected_premium", {
                required: "Anticipated premium is required",
              })}
            />
            {errors?.expected_premium && (
              <Text color="red.500" fontSize="sm">
                {errors.expected_premium.message}
              </Text>
            )}
          </FormControl>
          <FormControl isInvalid={Boolean(errors?.sum_insured)}>
            <FormLabel htmlFor="sum_insured">Limit of Indemnity*</FormLabel>
            <Input
              borderRadius="21px"
              background="white"
              bg="white"
              id="sum_insured"
              type="number"
              {...register("sum_insured", {
                required: "Total coverages is required",
              })}
            />
            {errors?.sum_insured && (
              <Text color="red.500" fontSize="sm">
                {errors.sum_insured.message}
              </Text>
            )}
          </FormControl>

          {/* <FormControl isInvalid={Boolean(errors?.Other_Details)}>
          <FormLabel htmlFor="Other_Details">Other Details</FormLabel>
          <Input
          borderRadius='21px'
          background='white' bg="white"
              
            id="Other_Details"
            {...register('Other_Details')}
          />
          {errors?.Other_Details && (
            <Text color="red.500" fontSize="sm">
              {errors.Other_Details.message}
            </Text>
          )}
        </FormControl> */}
        </HStack>
        {watch("policytype") === "Renewal" && (
          <HStack gap="4">
            <FormControl
              id="previous_insurer"
              isInvalid={Boolean(errors?.previous_insurer)}
            >
              <FormLabel>Previous Insurer*</FormLabel>
              <Input
                borderRadius="21px"
                background="white"
                bg="white"
                {...register("previous_insurer", {
                  required: "Previous insurer is required",
                })}
              />
              {errors?.previous_insurer && (
                <Text color="red.500" fontSize="sm">
                  {errors?.previous_insurer?.message}
                </Text>
              )}
            </FormControl>

            <FormControl
              id="previous_policy_expiry"
              isInvalid={Boolean(errors?.previous_policy_expiry)}
            >
              <FormLabel>Previous policy expiry*</FormLabel>
              <Input
                borderRadius="21px"
                background="white"
                bg="white"
                type="date"
                {...register("previous_policy_expiry", {
                  required: "Previous policy expiry is required",
                })}
              />
              {errors?.previous_policy_expiry && (
                <Text color="red.500" fontSize="sm">
                  {errors?.previous_policy_expiry?.message}
                </Text>
              )}
            </FormControl>
          </HStack>
        )}
        <HStack w="100%" justify="space-between" align="center" mt="20px">
          <Button
            type="button"
            borderRadius="21px"
            onClick={() => setStep(step - 1)}
          >
            Previous
          </Button>
          <Button type="submit" borderRadius="21px" colorScheme="green">
            Next
          </Button>
        </HStack>
      </VStack>
    </form>
  );
};

export default CyberInsurance;
